<template>
  <div>
    <template>
      <div id="app" class="login-main-header">
        <v-app id="inspire">
          <v-container fluid class="pt-0 pb-0">
            <v-row>
              <v-col lg="7" md="7" class="pt-0 pb-0">
                <v-form v-model="valid" ref="form">
                  <v-container class="login_container_css">
                    <v-row>
                      <v-col cols="12" md="8" offset-md="2" class="text-center">
                        <p class="login_text_css text-left">FORGOT PASSWORD</p>
                        <v-text-field
                          type="text"
                          placeholder="Username"
                          v-model="user.uid"
                          autocomplete="off"
                          single-line
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="8" offset-md="2" class="text-center">
                        <v-text-field
                          type="text"
                          placeholder="Email"
                          v-model="user.email"
                          autocomplete="off"
                          single-line
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="8" offset-md="2" class="text-center">
                        <v-text-field
                          type="text"
                          placeholder="F1 Email"
                          v-model="user.f1_email"
                          autocomplete="off"
                          single-line
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="8" offset-md="2">
                        <a href="javascript:void(0)">
                          <v-btn
                            x-large
                            rounded
                            class="header-add-forgot-password-btn"
                            style=""
                            @click="retrievePwd"
                            >Submit
                          </v-btn>
                        </a>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
                <template>
                  <v-footer padless class="Login-footer">
                    <v-col class="text-center footer-custome-css" cols="12">
                      Copyright. @ 2022 All Right Reserved | Peterson
                      Acquisitions..
                    </v-col>
                  </v-footer>
                </template>

                <div>
                  <!-- <b-button  v-b-modal.modal-center @click="show=true" variant="primary">Show Modal</b-button> -->

                  <b-modal
                    id="modal-center"
                    v-model="dlgError"
                    centered
                    :title="errorTitle"
                    :header-bg-variant="headerBgVariant"
                    :header-text-variant="headerTextVariant"
                    :body-bg-variant="bodyBgVariant"
                    :body-text-variant="bodyTextVariant"
                    :footer-bg-variant="footerBgVariant"
                    :footer-text-variant="footerTextVariant"
                  >
                    <b-row class="mb-1 text-center">
                      <b-col cols="12"></b-col>
                      <b-col>{{ errorText }}</b-col>
                    </b-row>

                    <template #modal-footer>
                      <div class="w-100">
                        <p class="float-left"></p>
                        <b-button
                          variant="primary"
                          size="sm"
                          class="float-right"
                          @click="dlgError = false"
                        >
                          Close
                        </b-button>
                      </div>
                    </template>
                  </b-modal>
                </div>
              </v-col>
              <v-col lg="5" md="5" class="pt-0 pb-0 login-image">
                <img src="../assets/login_view.png" />
              </v-col>
            </v-row>
          </v-container>
        </v-app>
      </div>
    </template>
  </div>
</template>

<script>
import HeaderToggler from "./mixins/HeaderToggler";
import CommonServices from "../service/CommonServices";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
  name: "Login",
  mixins: [HeaderToggler],
  data() {
    return {
      valid: false,

      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "danger",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "danger",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",
      dlgError: false,

      user: {
        uid: "",
        email: "",
        f1_email: "",
      },

      userInfo: {
        uid: "",
        nickname: "",
        email: "",
        f1_email: "",
        phone: "",
        text: "",
        pwd: "",
        sms_text: "",
        formatted_phone: "",
      },
    };
  },

  methods: {
    retrievePwd() {
      if (this.user.uid) {
        this.getPwdFromUID(this.user.uid);
      } else {
        if (this.user.email) {
          this.getPwdFromEmail(this.user.email);
        } else {
          if (this.user.f1_email) {
            this.getPwdFromF1Email(this.user.f1_email);
          }
        }
      }
    },

    getPwdFromUID(uid) {
      CommonServices.getItem("vwUserContactInfo/?uid=" + uid)
        .then((res) => {
          console.log("Get UID Response: ", res);
          console.log("Get UID Results: ", res.results);
          this.userInfo = res.results;
          console.log("User Info: ", this.userInfo);
          console.log("User Info Length: ", this.userInfo.length);
          if (this.userInfo.length > 0) {
            if (this.userInfo[0].pwd) {
              //alert('Pwd found!')
              if (this.userInfo[0].text) {
                this.userInfo[0].sms_text =
                  "Your F1 Pro Login password is: " + this.userInfo[0].pwd;
                this.sendTwil();
              } else {
                this.dlgError = true;
                this.errorTitle = "No Text Number found for this user!";
                this.errorText =
                  "No Text Number was found for this user.  Cannot send Password SMS";
              }
            } else {
              this.dlgError = true;
              this.errorTitle = "No Password found for this user!";
              this.errorText =
                "No Password was found for this user.  Cannot send Password SMS.  Please contact your System Administrator.";
            }
          } else {
            this.dlgError = true;
            this.errorTitle = "No login information found for this user!";
            this.errorText =
              "No login information was found for this user.  Cannot send Password SMS.  Please contact your System Administrator.";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPwdFromEmail(email) {
      CommonServices.getItem("vwUserContactInfo/?email=" + email)
        .then((res) => {
          console.log("Get UID Response: ", res);
          console.log("Get UID Results: ", res.results);
          this.userInfo = res.results;
          console.log("User Info: ", this.userInfo);
          console.log("User Info Length: ", this.userInfo.length);
          if (this.userInfo.length > 0) {
            if (this.userInfo[0].pwd) {
              //alert('Pwd found!')
              if (this.userInfo[0].text) {
                this.userInfo[0].sms_text =
                  "Your F1 Pro Login password is: " + this.userInfo[0].pwd;
                this.sendTwil();
              } else {
                this.dlgError = true;
                this.errorTitle = "No Text Number found for this user!";
                this.errorText =
                  "No Text Number was found for this user.  Cannot send Password SMS";
              }
            } else {
              this.dlgError = true;
              this.errorTitle = "No Password found for this user!";
              this.errorText =
                "No Password was found for this user.  Cannot send Password SMS.  Please contact your System Administrator.";
            }
          } else {
            this.dlgError = true;
            this.errorTitle = "No login information found for this user!";
            this.errorText =
              "No login information was found for this user.  Cannot send Password SMS.  Please contact your System Administrator.";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPwdFromF1Email(email) {
      CommonServices.getItem("vwUserContactInfo/?f1_email=" + email)
        .then((res) => {
          console.log("Get UID Response: ", res);
          console.log("Get UID Results: ", res.results);
          this.userInfo = res.results;
          console.log("User Info: ", this.userInfo);
          console.log("User Info Length: ", this.userInfo.length);
          if (this.userInfo.length > 0) {
            if (this.userInfo[0].pwd) {
              //alert('Pwd found!')
              if (this.userInfo[0].text) {
                this.userInfo[0].sms_text =
                  "Your F1 Pro Login password is: " + this.userInfo[0].pwd;
                this.sendTwil();
              } else {
                this.dlgError = true;
                this.errorTitle = "No Text Number found for this user!";
                this.errorText =
                  "No Text Number was found for this user.  Cannot send Password SMS";
              }
            } else {
              this.dlgError = true;
              this.errorTitle = "No Password found for this user!";
              this.errorText =
                "No Password was found for this user.  Cannot send Password SMS.  Please contact your System Administrator.";
            }
          } else {
            this.dlgError = true;
            this.errorTitle = "No login information found for this user!";
            this.errorText =
              "No login information was found for this user.  Cannot send Password SMS.  Please contact your System Administrator.";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    sendTwil() {
      var phoneNumber = parsePhoneNumberFromString(this.userInfo[0].text, "US");
      var smsbody = this.userInfo[0].sms_text;
      console.log("Mobile Phone: ", phoneNumber);
      console.log("SMS Text: ", smsbody);

      // this.activity_record.id=item.id
      // this.activity_record.customer_id=item.customer_id
      // this.activity_record.cust_activity_type_id=item.cust_activity_type_id
      // this.activity_record.campaign_element_id=item.campaign_element_id
      // this.activity_record.campaign_detail_id=item.campaign_detail_id
      // console.log("Activity Record: ",this.activity_record)
      if (phoneNumber.isValid()) {
        this.userInfo.formatted_phone = phoneNumber.number;
        console.log("Formatted Phone: ", this.userInfo.formatted_phone);
        CommonServices.sendTwilioPwd(
          this.userInfo.formatted_phone,
          smsbody,
          this.userInfo[0].nickname
        );
        this.snackbar = true;
        this.snackColor = "success";
        this.error =
          "An SMS containing your login password has been sent to your mobile phone number";
      } else {
        this.snackbar = true;
        this.snackColor = "error";
        this.error = "Invalid Phone Number!";
        console.log("Phone Number is Invalid: ", phoneNumber.number);
      }
    },
  },
};
</script>

<style type="text/css" scoped>
.login-image {
  height: 100vh;
  padding-right: 0;
}

.login-image img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.header-add-forgot-password-btn {
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  background: #0091ff !important;
  color: #fff !important;
}

.login_text_css {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
}

footer {
  position: absolute !important;
  bottom: 0;
  left: 0;
  max-width: 59%;
  right: 0 !important;
  background-color: transparent !important;
}
@media (max-width: 600px) {
  .login-image {
    display: none;
  }
}
</style>
